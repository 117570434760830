<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ page_name }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>平台数据</h3></el-col>
            </el-row>
            <!--搜索-->
            <div class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="15">
                            <el-form-item label="时间">
                                <el-select v-model="searchtype" style="width: 30%">
                                    <el-option label="月统计" value="month"></el-option>
                                    <el-option label="周统计" value="week"></el-option>
                                </el-select>
                                <el-date-picker
                                        v-if="searchtype == 'month'"
                                        v-model="time"
                                        type="month"
                                        placeholder="选择月"
                                        value-format="yyyy-MM"
                                >
                                </el-date-picker>
                                <el-date-picker
                                        v-if="searchtype == 'week'"
                                        v-model="selectWeek"
                                        type="week"
                                        format="yyyy-WW"
                                        placeholder="选择周"
                                        @change="createdTimeArrange">

                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" >搜索
                                </el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>

            <!--收入-->
            <h3>微信支付</h3>
            <el-row :gutter="16">
                <el-col :span="12">
                    <el-card shadow="hover">
                        <div id="main" style="width: 600px;height:400px;"></div>
                    </el-card>
                </el-col>
                <el-col :span="12">
                    <el-card shadow="hover">
                        <div id="main2" style="width: 600px;height:400px;"></div>
                    </el-card>
                </el-col>

            </el-row>
            <div style="height: 20px"></div>

        </div>
    </div>
</template>
<script>

import * as echarts from 'echarts';

export default {

    data() {
        return {
            searchtype: 'month',
            page_name: '报表管理',
            search: {},         // 搜索内容
            time: '',
            selectWeek: '',
            data_x: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            series_data: [23, 24, 18, 25, 27, 28, 1],

        }
    },
    // 创建
    created() {
        this.getdatatime()
    },
    // 安装
    mounted() {
        this.drawLine('main', this.data_x, this.series_data);
        this.drawLine('main2', this.data_x, this.series_data);
    },
    methods: {
        getdatatime() {
            let date = new Date();
            let y = date.getFullYear();
            let M = date.getMonth() + 1;
            let theDay = `${y.toString()}-${M.toString()}`;
            this.time = theDay;
        },
        // 创建排课时间表
        createdTimeArrange() {
            console.log('创建排课时间表')
            let selectWeek = this.selectWeek        // 选择的周数据

            // 创建排期时间表
            let DateArea = this.getDateArea(selectWeek)
            this.weekArrangeData.push(DateArea[0], DateArea[6])
        },
        // 获取选中的一周日期数据
        getDateArea(currentTime) {
            let currentDate = new Date(currentTime)
            let timesStamp = currentDate.getTime()
            let currenDay = currentDate.getDay()
            let dates = []
            for (let i = 0; i < 7; i++) {
                // let date = new Date(timesStamp + 24 * 60 * 60 * 1000 * (i - (currenDay + 6) % 7)).toLocaleDateString().replace(/\//g, '-')
                let date = new Date(timesStamp + 24 * 60 * 60 * 1000 * (i - (currenDay + 6) % 7)).toISOString().split('T')[0]
                dates.push({
                    date,
                });
            }
            return dates
        },

        drawLine(id, data_x, series_data) {

            var myChart = echarts.init(document.getElementById(id));
            var data = [];
            for (let i = 0; i < 5; ++i) {
                data.push(Math.round(Math.random() * 200));
            }

            myChart.setOption({
                title: {
                    text: '圆环图的例子',
                },
                xAxis: {
                    data: data_x
                },
                yAxis: {},
                series: [
                    {
                        type: 'bar',
                        data: series_data
                    }
                ]
            });

        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.d-flex {
    display: flex;
    align-items: center;
    justify-items: center;
}

.default {
    color: #409EFF;
}

.red {
    color: #fc142f;
}

.yellow {
    color: #F4BA28;
}

.blue {
    color: #637FFB;
}

.icon {
    font-size: 60px;
    margin-right: 20px;
}
</style>
